export const ACTION_TYPES = {
    SET_DROPDOWN_DETAIL: "SET_DROPDOWN_DETAIL",
    RESET_DROPDOWN_DETAIL: "RESET_DROPDOWN_DETAIL",
    SET_TOURNAMENT_DROPDOWN_DETAIL: "SET_TOURNAMENT_DROPDOWN_DETAIL"
}

export const setSportDropdownDetail = (data) => {
    localStorage.setItem('isDropdownSportGet', true)
    localStorage.setItem('DropdownSportGet', JSON.stringify(data.sportDropdownData));
    return {
        type: ACTION_TYPES.SET_DROPDOWN_DETAIL,
        ...data
    }
}

export const setTournamentDropdownDetail = (data) => {
    localStorage.setItem('isTournamentDropdownSet', true)
    localStorage.setItem('DropdownTournamentGet', JSON.stringify(data.tournamentDropdownData));
    return {
        type: ACTION_TYPES.SET_TOURNAMENT_DROPDOWN_DETAIL,
        ...data
    }
}

export const resetSportDropdownDetail = () => {
    localStorage.setItem('isDropdownSet', false);
    localStorage.setItem('DropdownSportGet', "");
    return {
        type: ACTION_TYPES.RESET_DROPDOWN_DETAIL,
    }
}

