export const ACTION_TYPES = {
    RESET_TOURNAMENT_DROPDOWN_DETAIL: "RESET_TOURNAMENT_DROPDOWN_DETAIL",
    SET_TOURNAMENT_DROPDOWN_DETAIL: "SET_TOURNAMENT_DROPDOWN_DETAIL"
}

export const setTournamentDropdownDetail = (data) => {
    localStorage.setItem('isTournamentDropdownSet', true)
    localStorage.setItem('DropdownTournamentGet', JSON.stringify(data.tournamentDropdownData));
    return {
        type: ACTION_TYPES.SET_TOURNAMENT_DROPDOWN_DETAIL,
        ...data
    }
}

export const resetTournamentDropdownDetail = () => {
    localStorage.setItem('isTournamentDropdownSet', false);
    localStorage.setItem('DropdownTournamentGet', "");
    return {
        type: ACTION_TYPES.RESET_TOURNAMENT_DROPDOWN_DETAIL,
    }
}

