import React, { useEffect, useRef, useState } from "react";
import { Input, Button, Space, Select } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";

export const isEqualsArray = (arr1, arr2) => {
	if (!(arr1 instanceof Array) || !(arr1 instanceof Array)) return false;

	if (arr1.length !== arr2.length) return false;

	if (arr1 instanceof Object && arr2 instanceof Object) {
		let isEqual = true,
			arrayLength = arr1.length;
		for (let index = 0; index < arrayLength && isEqual; index++) {
			if (JSON.stringify(arr1[index]) !== JSON.stringify(arr2[index]))
				isEqual = false;
		}

		return isEqual;
	}

	return false;
};

export function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key)) return false;
	}
	return true;
}

export function isIEBrowser() {
	// BROWSER CHECK VARIABLES
	const ua = window.navigator.userAgent;
	const msie = ua.indexOf("MSIE ");
	const msie11 = ua.indexOf("Trident/");
	return msie > 0 || msie11 > 0;
}
export const handleReset = (clearFilters, confirm) => {
	clearFilters();
	confirm();
};

export function getColumnSearchProps(dataIndex) {
	let searchInput = null;

	return {
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						searchInput = node;
					}}
					placeholder={`Search`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() => {
						confirm();
					}}
					style={{ width: 188, marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => {
							confirm({ closeDropdown: true });
						}}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters, confirm)}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => (
			<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
		),
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex]
						.toString()
						.toLowerCase()
						.includes(value.toLowerCase())
				: "",
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => searchInput.select(), 100);
			}
		},
		render: (text) => text,
	};
}

export const getFilterProps = (
	dataIndex,
	searchList,
	isEmailFilterApplied,
	searchInput = ""
) => ({
	filterDropdown: ({
		setSelectedKeys,
		selectedKeys,
		confirm,
		clearFilters,
	}) => (
		<div style={{ padding: 8 }}>
			<Select
				ref={(node) => {
					searchInput = node;
				}}
				defaultValue={searchList[0].name}
				placeholder="Select option"
				optionFilterProp="children"
				allowClear
				onClear={clearFilters}
				onChange={(e) => (
					setSelectedKeys(e !== undefined || e !== null ? [e] : []),
					e !== undefined && confirm()
				)}
				style={{ marginRight: "20px", width: "150px" }}
			>
				{searchList.map((item) => {
					return (
						<Select.Option value={item.id} key={item.id}>
							{item.name ? item.name : ""}
						</Select.Option>
					);
				})}
			</Select>
		</div>
	),
	filterIcon: (filtered) => (
		<FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
	),
	onFilter: (value, record) =>
		value === true ? "true" : value === false ? "false" : value,
	onFilterDropdownVisibleChange: (visible) => {
		if (visible) {
		}
	},
});

export function setPaginationObject(
	currentPagination,
	pagination,
	filters,
	sorter
) {
	let newPaginationInfo = {
		...currentPagination,
		current: isEmpty(pagination) ? 1 : pagination.current,
		pageSize: isEmpty(pagination) ? 10 : pagination.pageSize,
	};

	if (filters) {
		newPaginationInfo.search = [];
		for (const key in filters) {
			if (filters[key]) {
				newPaginationInfo.search.push({
					field: key,
					value: filters[key][0],
				});
			}
		}
	}

	if (sorter.field) {
		newPaginationInfo.sort = {
			field: sorter.field,
			order: sorter.order === "ascend" ? "ASC" : "DESC",
		};
	}

	return newPaginationInfo;
}

export function useDebounce(value, delay) {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value);
	useEffect(
		() => {
			// Update debounced value after delay
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay);
			// Cancel the timeout if value changes (also on delay change or unmount)
			// This is how we prevent debounced value from updating if value is changed ...
			// .. within the delay period. Timeout gets cleared and restarted.
			return () => {
				clearTimeout(handler);
			};
		},
		[value, delay] // Only re-call effect if value or delay changes
	);
	return debouncedValue;
}
