import { Api } from "Helpers";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  URL_HEALTH_SAFETY,
  URL_ABOUT_US,
  URL_TERMS,
  URL_PRIVACY,
  URL_HOW_IT_WORKS,
  URL_CANCELLATION,
  URL_CONTACT_US,
  URL_GETTING_STARTED,
  URL_REFERRAL_EARNING,
  URL_ANQA_SUBSCRIPTION_PLAN,
  URL_ANQA_POINTS,
  URL_KNOW_MORE_ABOUT_PAYOUT,
} from "Helpers/Paths";
import { useLocation } from "react-router-dom";

export default function StaticPages() {
  const location = useLocation();
  const path = location.pathname;
  let [pageContent, setpageContent] = useState("");

  useEffect(() => {
    viewStaticData();
  }, []);

  async function viewStaticData() {
    let response = await new Api().get(
      "/anqa/v1/master/get-static-page-before-sign-in",
      {
        params: {
          pageName:
            path === URL_HEALTH_SAFETY
              ? "Health & Safety"
              : path === URL_ABOUT_US
              ? "About Us"
              : path === URL_TERMS
              ? "Terms and Conditions"
              : path === URL_PRIVACY
              ? "Privacy Policy"
              : path === URL_HOW_IT_WORKS
              ? "How It Works"
              : path === URL_KNOW_MORE_ABOUT_PAYOUT
              ? "Know more about payout"
              : path === URL_CONTACT_US
              ? "Contact Us"
              : path === URL_CANCELLATION
              ? "Cancellation Policy"
              : path === URL_GETTING_STARTED
              ? "Getting started with Anqa"
              : path === URL_REFERRAL_EARNING
              ? "Anqa's points"
              : path === URL_ANQA_POINTS
              ? "Anqa's points"
              : path === URL_ANQA_SUBSCRIPTION_PLAN &&
                "Anqa's subscription plan",
        },
        skipAuth: true,
      }
    );
    setpageContent(response.data.pageContent);
  }

  return (
    <div
      style={
        path === URL_PRIVACY || path === URL_TERMS
          ? { background: "white", height: "100%" }
          : { background: "white", height: "100vh" }
      }
    >
      <div style={{ margin: "20px" }}>
        <div
          dangerouslySetInnerHTML={{
            __html: pageContent,
          }}
        />
      </div>
    </div>
  );
}
