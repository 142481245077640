export const ACTION_TYPES = {
    SET_QUESTION_DETAIL: "SET_QUESTION_DETAIL",
}

export const setQuestionDetail = (data) => {
    localStorage.setItem('QuestionDetail', JSON.stringify(data.questionData));
    return {
        type: ACTION_TYPES.SET_QUESTION_DETAIL,
        ...data
    }
}