import React from "react";
import { Switch, Route } from "react-router-dom";

// COMPONENTS
import Login from "Components/Pages/Login/Login";
import ForgotPassword from "Components/ForgotPassword/ForgotPassword";
import PrivacyPolicy from "Components/Static/PrivacyPolicy";

// ROUTING COMPONENTS
import ProtectedRoute from "./ProtectedRoute";
import RoutesList from "./RoutesList";
import {
  URL_LOGIN,
  URL_FORGOT_PASSWORD,
  URL_RESET_PASSWORD,
  URL_HEALTH_SAFETY,
  URL_ABOUT_US,
  URL_TERMS,
  URL_PRIVACY,
  URL_HOW_IT_WORKS,
  URL_CANCELLATION,
  URL_CONTACT_US,
  URL_ACCOUNT_LINK_SUCCESS,
  URL_ACCOUNT_LINK_ERROR,
  URL_GETTING_STARTED,
  URL_REFERRAL_EARNING,
  URL_ANQA_SUBSCRIPTION_PLAN,
  URL_ANQA_POINTS,
  URL_KNOW_MORE_ABOUT_PAYOUT,
} from "Helpers/Paths";
import ResetPassword from "Components/ResetPassword/ResetPassword";
import StaticPages from "Components/StaticPages/Staticpages";
import AccountLink from "Components/StaticPages/AccountLink";

function Routes() {
  return (
    <Switch>
      <Route exact path={URL_LOGIN} component={Login} />
      <Route exact path={URL_FORGOT_PASSWORD} component={ForgotPassword} />
      <Route exact path={URL_RESET_PASSWORD} component={ResetPassword} />
      <Route exact path={URL_HEALTH_SAFETY} component={StaticPages} />
      <Route exact path={URL_ABOUT_US} component={StaticPages} />
      <Route exact path={URL_TERMS} component={StaticPages} />
      <Route exact path={URL_PRIVACY} component={StaticPages} />
      <Route exact path={URL_HOW_IT_WORKS} component={StaticPages} />
      <Route exact path={URL_KNOW_MORE_ABOUT_PAYOUT} component={StaticPages} />
      <Route exact path={URL_CANCELLATION} component={StaticPages} />
      <Route exact path={URL_CONTACT_US} component={StaticPages} />
      <Route exact path={URL_GETTING_STARTED} component={StaticPages} />
      <Route exact path={URL_REFERRAL_EARNING} component={StaticPages} />
      <Route exact path={URL_ANQA_SUBSCRIPTION_PLAN} component={StaticPages} />
      <Route exact path={URL_ANQA_POINTS} component={StaticPages} />
      <Route exact path={URL_ACCOUNT_LINK_SUCCESS} component={AccountLink} />
      <Route exact path={URL_ACCOUNT_LINK_ERROR} component={AccountLink} />
      <ProtectedRoute>
        {RoutesList.map((route, index) => (
          <React.Fragment key={index}>
            {route.sidebar && route.sidebar.nested ? (
              route.sidebar.nested.map((child, index) => (
                <Route
                  key={index}
                  path={child.path}
                  exact={child.exact}
                  component={child.component}
                />
              ))
            ) : (
              <Route
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            )}
          </React.Fragment>
        ))}
      </ProtectedRoute>
    </Switch>
  );
}

export default Routes;
