import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button, Input, Form } from "antd";

// STYLES
import { LoginWrapper, LoginBox, LogoWrapper } from "./Login.style";

// CONST
import { URL_DASHBOARD } from "Helpers/Paths";
import { loginUser } from "Redux/Auth/Actions";

import Api from "Helpers/ApiHandler";

// IMAGES
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import LogoImage from "Assets/Images/logo.png";

function Login(props) {
	let [isLoading, setIsLoading] = useState(false);
	const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);
	const dispatch = useDispatch();

	useEffect(() => {
		checkIfAlreadyLoggedIn();
		// eslint-disable-next-line
	}, []);

	function checkIfAlreadyLoggedIn() {
		if (isLoggedIn) props.history.push(URL_DASHBOARD);
	}

	function forgotPassword() {
		props.history.push("/forgot-password");
	}

	async function login(values) {
		let dataValues = { ...values };
		dataValues.deviceName = "cms";
		dataValues.deviceToken = "";
		dataValues.deviceId = "";
		setIsLoading(true);
		new Api()
			.post("/anqa/v1/admin/login", {
				data: dataValues,
				returnUnhandledError: true,
			})
			.then(async (response) => {
				dispatch(loginUser({ accessToken: response.data.accessToken }));
				props.history.push(URL_DASHBOARD);
			})
			.catch((error) => {
				setIsLoading(false);
			});
	}

	return (
		<LoginWrapper className="flex f-v-center f-h-center">
			<LoginBox className="box">
				<LogoWrapper>
					<img className="img" src={LogoImage} alt="Logo" />
				</LogoWrapper>

				<Form className="form-container" onFinish={login}>
					<Form.Item
						name="emailId"
						rules={[
							{
								type: "email",
								message: "Please Enter Valid Email",
							},
							{
								required: true,
								message: "Please Enter Your Email",
							},
						]}
					>
						<Input type="email" size="large" placeholder="Enter Your Email" />
					</Form.Item>
					<Form.Item
						name="password"
						rules={[
							{
								required: true,
								message: "Please Enter Your Password!",
							},
						]}
					>
						<Input.Password
							placeholder="Enter Your Password"
							size="large"
							iconRender={(visible) =>
								visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
							}
						/>
					</Form.Item>
					<div className="redirection-link" onClick={forgotPassword}>
						<a>Forgot Password?</a>
					</div>
					<div className="button-container">
						<Button
							block
							className="btn"
							shape="round"
							size="large"
							htmlType="submit"
							loading={isLoading}
						>
							Login
						</Button>
					</div>
				</Form>
			</LoginBox>
		</LoginWrapper>
	);
}

export default Login;
