export const MODAL_TYPES = {
  VIEW: "VIEW",
  EDIT: "EDIT",
  ADD: "ADD",
  GIVE: "GIVE",
  DELETE: "DELETE",
};

export const PAGINATION = {
  PER_PAGE: 10,
};

export const TEMPLATE_TYPES = {
  EMAIL: "EMAIL",
  NOTIFICATION: "NOTIFICATION",
};

export const WEEK_DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
