
import { ACTION_TYPES } from './Actions';

let Data = localStorage.getItem('DropdownSportGet') || "";
const newData = Data && JSON.parse(Data);
const initState = {
    isDropdownSportGet: localStorage.getItem('isDropdownSportGet') ? (localStorage.getItem('isDropdownSportGet') === "true") : false,
    DropdownSportGet: newData,
}

const SportDropdownReducer = (state = initState, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_DROPDOWN_DETAIL:
            return {
                ...state,
                isDropdownSportGet: true,
                DropdownSportGet: action.sportDropdownData,
            };

        case ACTION_TYPES.RESET_DROPDOWN_DETAIL:
            return {
                ...state,
                isDropdownSportGet: false,
                DropdownSportGet: "",
            };

        default:
            return state;
    }

}

export default SportDropdownReducer;