import React from "react";
import { Result } from "antd";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css";
import logo from "../../Assets/Images/anqaLogo.png";
import { useLocation } from "react-router-dom";

export default function AccountLink() {
  const location = useLocation();
  const path = location.pathname;

  return (
    <div style={{ background: "white", height: "100vh" }}>
      <div style={{ marginTop: "1em" }}>
        <div>
          <img
            src={logo}
            style={{
              width: "10%",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            alt="loading..."
          />
        </div>
        <br />
        <br />
        {path === "/account-link-success" ? (
          <Result
            status="success"
            title="Your Account was linked successfully!"
          />
        ) : (
          <Result
            status="warning"
            title="Your account was not linked successfully!"
          />
        )}
      </div>
      {/* Footer */}
      <div
        style={{
          backgroundColor: "red",
          color: "white",
          position: "fixed",
          left: "0",
          bottom: "0",
          width: "100%",
        }}
      >
        <p style={{ textAlign: "center", marginTop: ".7em", fontSize: "18px" }}>
          Copyright © 2023 ANQA. All rights reserved.
        </p>
      </div>
    </div>
  );
}
