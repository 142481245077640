import Auth from "./Auth/Reducer";
import General from "./General/Reducer";
import SportDetailReducer from "./SportsDetails/Reducer";
import QuestionDetailReducer from "./QuestionsDetails/Reducer";
import SportDropdownReducer from "./SportDropdownDetails/Reducer";
import TournamentDropdownReducer from "./TournamentDropdownDetails/Reducer";

export default {
    Auth,
    General,
    SportDetailReducer,
    QuestionDetailReducer,
    SportDropdownReducer,
    TournamentDropdownReducer
}