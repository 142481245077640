
import { ACTION_TYPES } from './Actions';


const initState = {
    toast: {
        enable: false,
        message: ""
    },
    isOpen: false,
    sport: {
        sportId: 0,
        tournamentId: 0
    }
}

const Reducer = (state = initState, action) => {
    switch (action.type) {

        case ACTION_TYPES.TOAST_MESSAGE_ENABLE:
            return {
                ...state,
                toast: {
                    enable: true,
                    message: action.message
                }
            };

        case ACTION_TYPES.TOAST_MESSAGE_DISABLE:
            return {
                ...state,
                toast: {
                    enable: false,
                    message: ''
                }
            };
//--------
            case ACTION_TYPES.COLLAPSE_BAR:
			return {
				...state,
				isOpen: !state.isOpen,
			};

        case ACTION_TYPES.SET_SELECTED_SPORT:
            return {
                ...state,
                sport: {
                    sportId: action.sportId && action.sportId,
                    tournamentId: action.tournamentId && action.tournamentId
                }
            };

        default:
            return state;
    }

}

export default Reducer;