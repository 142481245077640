export const ACTION_TYPES = {
	SHOW_SPORT_DETAIL: "SHOW_SPORT_DETAIL",
	SET_SPORT_DETAIL: "SET_SPORT_DETAIL",
	SET_GOLF_SPORT_DETAIL: "SET_GOLF_SPORT_DETAIL",
};

export const setLiveSportDetail = (data) => {
	localStorage.setItem("isLiveSportGet", true);
	localStorage.setItem("LiveSportGet", JSON.stringify(data.sportData));
	return {
		type: ACTION_TYPES.SET_SPORT_DETAIL,
		...data,
	};
};

export const setLiveGolfSportDetail = (data) => {
	localStorage.setItem("isLiveSportGet", true);
	localStorage.setItem("LiveGolfSportGet", JSON.stringify(data.sportData));
	return {
		type: ACTION_TYPES.SET_GOLF_SPORT_DETAIL,
		...data,
	};
};

export const showSportDetail = (message) => {
	return {
		type: ACTION_TYPES.SHOW_SPORT_DETAIL,
	};
};
