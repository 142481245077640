import styled from "styled-components";
import bg1 from "Assets/Images/background.png";

export const LoginWrapper = styled.div`
    height  : calc(100vh - 0px);
    background-image: url(${bg1});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
`

export const LoginBox = styled.div`
    padding         : 30px;
    width           : 500px;

    .form-container {
        margin  : 20px auto 30px;
        width   : 90%;
    }
    .redirection-link {
        text-align:center;
        margin-bottom: 2%;
    }
    .redirection-link a{
        color:black;
        font-weight:bold;
    }
    .button-container {
        text-align: center;
        width: 50%;
        margin: auto;
        .btn {
            color:#ffffff;
            background-color: #e62a35;
            text-transform  : uppercase;
            min-width   : 125px;
            min-height  : 45px;
        }
    }
`

export const LogoWrapper = styled.div`
    text-align  : center;
    width       : 100%;

    .img {
        max-height : 150px;
    }
`