import React, { useEffect, useState } from "react";
import { Input, Form } from "antd";
import Api from "Helpers/ApiHandler";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css";
import logo from "../../Assets/Images/anqaLogo.png";
import { Helmet } from "react-helmet";

export default function PrivacyPolicy() {
	let [form] = Form.useForm(),
		[pageName, setpageName] = useState(),
		[pageContent, setpageContent] = useState("");
	const [isLoading, setLoading] = useState(true);

	useEffect(() => {
		viewStaticData(pageContent);
	}, []);

	async function viewStaticData() {
		let response = await new Api().get(
			"/anqa/v1/master/get-static-page-before-sign-in",
			{
				params: {
					pageName: "Privacy Policy",
				},
				skipAuth: true,
			}
		);
		setpageContent(response.data.pageContent);
		setLoading(false);
	}

	return (
		<>
			<Helmet>
				<title>Privacy Policy</title>
				<meta name="description" content="Anqa CMS Privacy Policy" />
			</Helmet>
			<div isLoading={isLoading} style={{ marginTop: "1em" }}>
				<div>
					<img
						src={logo}
						style={{
							width: "10%",
							display: "block",
							marginLeft: "auto",
							marginRight: "auto",
						}}
					/>
				</div>
				<br />
				<br />
				<div style={{ marginLeft: "10em" }}>
					<p style={{ fontSize: "30px" }}>
						<b>PRIVACY POLICY</b>
					</p>
					<div
						dangerouslySetInnerHTML={{
							__html: pageContent,
						}}
					/>
				</div>
			</div>
			{/* Footer */}
			<div
				style={{
					backgroundColor: "red",
					color: "white",
					position: "fixed",
					left: "0",
					bottom: "0",
					width: "100%",
				}}
			>
				<p style={{ textAlign: "center", marginTop: ".7em", fontSize: "18px" }}>
					Copyright © 2022 ANQA. All rights reserved.
				</p>
			</div>
		</>
	);
}
