import React, { useState } from "react";
import { Input, Form, Button, message } from "antd";
import Api from "Helpers/ApiHandler";
import OTPInput from "otp-input-react";

import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import { URL_LOGIN } from "Helpers/Paths";

// STYLES
import { LoginWrapper, LoginBox, LogoWrapper } from "./ForgotPassword.style";
import LogoImage from "Assets/Images/logo.png";

export default function ForgotPassword(props) {
	let [loading, setLoading] = useState(false),
		[isOtpGet, setIsOtpGet] = useState(false),
		[isEmailReq, setIsEmailReq] = useState(false),
		[verificationToken, setVerificationToken] = useState("");

	function login() {
		props.history.push(URL_LOGIN);
	}
	async function formSubmit(values) {
		let dataValues = { ...values };
		if (isOtpGet) {
			dataValues.verificationToken = verificationToken;
		}

		try {
			setLoading(true);
			let config = {
				data: dataValues,
				returnUnhandledError: true,
				returnError: true,
			};
			let response;
			if (isOtpGet) {
				response = await new Api().put("/anqa/v1/admin/reset-password", config);
				props.history.push(URL_LOGIN);
			} else {
				response = await new Api().post(
					`/anqa/v1/admin/forgot-password?email=${dataValues.email}`,
					config
				);
				setVerificationToken(response.data);
				setIsOtpGet(true);
				setIsEmailReq(false);
			}

			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log("Error", error);
		}
	}

	return (
		<LoginWrapper className="flex f-v-center f-h-center">
			<LoginBox className="box">
				<LogoWrapper>
					<img className="img" src={LogoImage} alt="Logo" />
				</LogoWrapper>
				{/* <PageTitle>Forgot Password</PageTitle> */}
				<Form className="form-container" onFinish={formSubmit}>
					<Form.Item
						name="emailId"
						rules={[
							{
								type: "email",
								message: "Please Enter Valid Email",
							},
							{
								required: true,
								message: "Please Enter Your Email",
							},
						]}
					>
						<Input
							size="large"
							placeholder="Email"
							disabled={isOtpGet && !isEmailReq ? true : false}
						/>
					</Form.Item>
					{isOtpGet && (
						<>
							<Form.Item
								name="otp"
								rules={[{ required: true, message: "Please enter your otp" }]}
							>
								<OTPInput
									className="otp-input-container"
									inputClassName="otp-input"
									autoFocus
									OTPLength={6}
									otpType="number"
									disabled={false}
									secure
								/>
							</Form.Item>
							<Form.Item
								name="newPassword"
								rules={[
									{
										pattern: new RegExp(
											"^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
										),
										message:
											"Password must be at least eight characters and must also include one capital letter, one special character and number",
									},
									{
										required: true,
										message: "Please Enter Your Password!",
									},
								]}
							>
								<Input.Password
									placeholder="New Password"
									size="large"
									iconRender={(visible) =>
										visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
									}
								/>
							</Form.Item>
							<Form.Item
								name="confirm"
								dependencies={["newPassword"]}
								hasFeedback
								rules={[
									{
										required: true,
										message: "Please Enter Confirm Password!",
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue("newPassword") === value) {
												return Promise.resolve();
											}

											return Promise.reject(
												new Error(
													"The two passwords that you entered do not match!"
												)
											);
										},
									}),
								]}
							>
								<Input.Password placeholder="Confirm Password" size="large" />
							</Form.Item>
						</>
					)}
					<Form.Item>
						{!isOtpGet ? (
							<div
								className="otp-link"
								onClick={() => {
									setIsOtpGet(true);
									setIsEmailReq(true);
								}}
							>
								<a>Already have an otp?</a>
							</div>
						) : (
							<div className="otp-link" onClick={() => setIsOtpGet(false)}>
								<a>Resend OTP?</a>
							</div>
						)}

						{!isOtpGet ? (
							<div className="button-container">
								<Button
									shape="round"
									className="submitButton btn"
									loading={loading}
									htmlType="submit"
								>
									Send OTP
								</Button>
							</div>
						) : (
							<div className="button-container">
								<Button
									shape="round"
									className="submitButton btn"
									loading={loading}
									htmlType="submit"
								>
									Submit
								</Button>
							</div>
						)}
						<div className="redirection-link" onClick={login}>
							<a>Already have an account? Sign In</a>
						</div>
					</Form.Item>
				</Form>
			</LoginBox>
		</LoginWrapper>
	);
}
