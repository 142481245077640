// CORE
import React from "react";
import { useSelector } from "react-redux";
import { Switch, Route } from "react-router-dom";
import PrivacyPolicy from "Components/Static/PrivacyPolicy";

// USER DEFINED
import Routes from "Routes/Route";
import Sidebar from "./Commons/Sidebar/Sidebar";
import Header from "./Commons/Header/Header";

//STYLES
import {
	RightContentWrapper,
	ContentWrapper,
	LayoutStyled,
} from "./Website.style";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useDevice } from "Helpers/useDevice";
import {
	URL_ABOUT_US,
	URL_ACCOUNT_LINK_ERROR,
	URL_ACCOUNT_LINK_SUCCESS,
	URL_ANQA_POINTS,
	URL_ANQA_SUBSCRIPTION_PLAN,
	URL_CANCELLATION,
	URL_CONTACT_US,
	URL_GETTING_STARTED,
	URL_HEALTH_SAFETY,
	URL_HOW_IT_WORKS,
	URL_KNOW_MORE_ABOUT_PAYOUT,
	URL_PRIVACY,
	URL_REFERRAL_EARNING,
	URL_TERMS,
} from "Helpers";

function Website(props) {
	const location = useLocation();
	const { isTabletOrMobile } = useDevice();
	const [showPage, setShowPage] = useState(false);
	const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);
	useEffect(() => {
		setShowPage(
			location.pathname === "/privacy-policy" ||
				location.pathname === URL_ACCOUNT_LINK_ERROR ||
				location.pathname === URL_ACCOUNT_LINK_SUCCESS ||
				location.pathname === URL_ANQA_POINTS ||
				location.pathname === URL_ANQA_SUBSCRIPTION_PLAN ||
				location.pathname === URL_REFERRAL_EARNING ||
				location.pathname === URL_GETTING_STARTED ||
				location.pathname === URL_CONTACT_US ||
				location.pathname === URL_CANCELLATION ||
				location.pathname === URL_HOW_IT_WORKS ||
				location.pathname === URL_PRIVACY ||
				location.pathname === URL_TERMS ||
				location.pathname === URL_ABOUT_US ||
				location.pathname === URL_HEALTH_SAFETY ||
				location.pathname === URL_KNOW_MORE_ABOUT_PAYOUT
				? true
				: false
		);
	}, []);

	return (
		<React.Fragment>
			<LayoutStyled>
				{isLoggedIn ? (
					<LoggedInComponents showPage={showPage} isMobile={isTabletOrMobile} />
				) : location.pathname === "/privacy-policy" ? (
					<>
						<Switch>
							<Route exact path="/privacy-policy" component={PrivacyPolicy} />
						</Switch>
					</>
				) : (
					<>
						<Routes />
					</>
				)}
			</LayoutStyled>
		</React.Fragment>
	);
}

function LoggedInComponents(props) {
	let { isMobile } = props;
	let [clicked, setClicked] = useState(false);

	const drawerClick = () => setClicked(!clicked);

	return (
		<>
			{props.showPage ? (
				<Routes />
			) : (
				<>
					<Sidebar isOpenedDrawer={clicked} onClose={drawerClick} />
					<RightContentWrapper isLoggedIn={true} isMobile={isMobile}>
						<Header drawerClick={() => drawerClick()} />
						<ContentWrapper isLoggedIn={true}>
							<Routes />
						</ContentWrapper>
					</RightContentWrapper>
				</>
			)}
		</>
	);
}

export default Website;
