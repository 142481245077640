import React from "react";
import { useDispatch } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import UserIcon from "Assets/Images/avatar.png";
import {
	Menu,
	Dropdown,
	Button,
	Space,
	Modal,
	Popover,
	Form,
	Input,
} from "antd";

import {
	LogoutOutlined,
	ProfileFilled,
	MenuOutlined,
	BellOutlined,
} from "@ant-design/icons";
import { ExclamationCircleOutlined } from "@ant-design/icons";

// STYLES
import { HeaderWrapper } from "./Header.style";

// REDUX
import { logOutUser } from "Redux/Auth/Actions";
import { useDevice } from "Helpers/useDevice";
import { useState } from "react";
import { Api } from "Helpers";

function Topbar(props) {
	let { drawerClick } = props;
	const { isTabletOrMobile } = useDevice();
	const match = useRouteMatch({
		path: "/reset-password/:token",
		strict: true,
		sensitive: true,
	});
	let [form] = Form.useForm();

	const dispatch = useDispatch();
	const { confirm } = Modal;
	const [loading, setLoading] = useState(false);
	const [open, setopen] = useState(false);

	const hide = () => {
		setopen(false);
	};

	function showConfirm() {
		confirm({
			title: "Are You Sure You Want To Logout ?",
			icon: <ExclamationCircleOutlined />,
			onOk() {
				dispatch(logOutUser());
			},
			onCancel() {},
		});
	}

	const userMenu = (
		<Menu className="drp-down-menu">
			<Menu.Item key="1" icon={<ProfileFilled style={{ fontSize: "25px" }} />}>
				<Link to="/change-password">Change Password</Link>
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item
				key="2"
				icon={<LogoutOutlined style={{ fontSize: "20px" }} />}
				onClick={showConfirm}
			>
				Logout
			</Menu.Item>
		</Menu>
	);

	const sendNotification = async (values) => {
		try {
			setLoading(true);
			await new Api().post(`/anqa/v1/cms/notification/general-notification`, {
				data: {
					title: values.title,
					body: values.body,
				},
			});
			setLoading(false);
			form.resetFields();
			hide();
		} catch (error) {
			setLoading(false);
		}
	};

	const content = (
		<Form
			form={form}
			layout="vertical"
			onFinish={sendNotification}
			size="default"
		>
			<Form.Item
				name="title"
				label="Title"
				rules={[{ required: true, message: "Enter title" }]}
			>
				<Input size="middle" placeholder="title" />
			</Form.Item>
			<Form.Item
				name="body"
				label="Body"
				rules={[{ required: true, message: "Enter Body" }]}
			>
				<Input.TextArea size="middle" placeholder="body" />
			</Form.Item>
			<Button
				className="submitButton"
				style={{ background: "#e62a35", color: "#ffffff" }}
				htmlType="submit"
				loading={loading}
			>
				Send Notification
			</Button>
		</Form>
	);

	return match?.isExact ? (
		""
	) : (
		<HeaderWrapper isMobile={isTabletOrMobile}>
			{isTabletOrMobile && (
				<div onClick={() => drawerClick()}>
					<MenuOutlined style={{ fontSize: "20px" }} className="pl-20 pt-23" />
				</div>
			)}
			<div></div>
			<div className="actions">
				<Space size="large">
					<Popover
						content={content}
						trigger="click"
						visible={open}
						onVisibleChange={(open) => setopen(open)}
					>
						<BellOutlined style={{ fontSize: "16px" }} />
					</Popover>
					<Dropdown
						className="dropdown-btn "
						overlay={userMenu}
						trigger={["click"]}
					>
						<img
							className="icon"
							style={{
								height: "20px",
							}}
							src={UserIcon}
							alt="Logo"
						/>
					</Dropdown>
				</Space>
			</div>
		</HeaderWrapper>
	);
}

export default Topbar;
