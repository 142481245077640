
import { ACTION_TYPES } from './Actions';

let Data = localStorage.getItem('DropdownTournamentGet') || "";
const newData = Data && JSON.parse(Data);
const initState = {
    isTournamentDropdownSet: localStorage.getItem('isTournamentDropdownSet') ? (localStorage.getItem('isTournamentDropdownSet') === "true") : false,
    DropdownTournamentGet: newData,
}

const TournamentDropdownReducer = (state = initState, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_TOURNAMENT_DROPDOWN_DETAIL:
            return {
                ...state,
                isTournamentDropdownSet: true,
                DropdownTournamentGet: action.tournamentDropdownData,
            };

        case ACTION_TYPES.RESET_TOURNAMENT_DROPDOWN_DETAIL:
            return {
                ...state,
                isTournamentDropdownSet: false,
                DropdownTournamentGet: ""
            };

        default:
            return state;
    }

}

export default TournamentDropdownReducer;