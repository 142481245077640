import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Drawer, Layout, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { CloseCircleOutlined } from "@ant-design/icons";

// STYLES
import {
	SidebarWrapper,
	MenuStyled,
	MenuItem,
	SubMenu,
	LogoWrapper,
} from "./Sidebar.style";

// IMAGES
import LogoImage from "Assets/Images/logo.png";

// CONST
import Routes from "Routes/RoutesList";
import { DIMENSIONS } from "Styles/Constants";
import { useDevice } from "Helpers/useDevice";

function Sidebar(props) {
	let { isOpenedDrawer, onClose } = props;
	const { isTabletOrMobile } = useDevice();
	let [routes, setRoutes] = useState([]),
		isOpen = useSelector((state) => state.General.isOpen),
		[collapsed, setcollapsed] = useState(false);

	const { Sider } = Layout;

	useEffect(() => {
		let filtered = Routes.filter((route) => route.sidebar);
		setRoutes(filtered);
	}, []);

	function listItemClick(route) {
		onClose();
		props.history.push(route.path);
	}

	const toggle = () => {
		setcollapsed(!collapsed);
	};

	return (
		<>
			{isTabletOrMobile ? (
				<Drawer
					placement="left"
					width={240}
					onClose={onClose}
					visible={isOpenedDrawer}
					bodyStyle={{ padding: 0 }}
					drawerStyle={{ padding: 0 }}
					headerStyle={{ display: "none" }}
				>
					<SidebarWrapper width={DIMENSIONS.SIDEBAR.FULL} collapsed={isOpen}>
						<div className="icon-position" onClick={() => onClose()}>
							<CloseCircleOutlined
								style={{ fontSize: "20px", color: "white" }}
							/>
						</div>
						<LogoWrapper className="flex f-v-center f-h-center">
							<img className="img" src={LogoImage} alt="Logo" />
						</LogoWrapper>
						<MenuStyled
							mode="inline"
							defaultSelectedKeys={[props.history.location.pathname]}
						>
							{routes.map((route) => {
								return route.sidebar.nested ? (
									<SubMenu
										key={route.path}
										icon={route.sidebar.icon}
										title={route.sidebar.name}
									>
										{route.sidebar.nested.map((child) =>
											child.name === "Static Content Management" ? (
												<Tooltip title={child.name}>
													<MenuItem
														key={child.path}
														onClick={() => {
															listItemClick(child);
														}}
													>
														{child.name}
													</MenuItem>
												</Tooltip>
											) : (
												<MenuItem
													key={child.path}
													onClick={() => {
														listItemClick(child);
													}}
												>
													{child.name}
												</MenuItem>
											)
										)}
									</SubMenu>
								) : (
									<MenuItem
										key={route.path}
										icon={route.sidebar.icon}
										onClick={() => {
											listItemClick(route);
										}}
									>
										{route.sidebar.name}
									</MenuItem>
								);
							})}
						</MenuStyled>
						{/* </Sider> */}
					</SidebarWrapper>
				</Drawer>
			) : (
				<SidebarWrapper width={DIMENSIONS.SIDEBAR.FULL} collapsed={isOpen}>
					<LogoWrapper className="flex f-v-center f-h-center">
						<img className="img" src={LogoImage} alt="Logo" />
					</LogoWrapper>
					<MenuStyled
						mode="inline"
						defaultSelectedKeys={[props.history.location.pathname]}
					>
						{routes.map((route) => {
							return route.sidebar.nested ? (
								<SubMenu
									key={route.path}
									icon={route.sidebar.icon}
									title={route.sidebar.name}
								>
									{route.sidebar.nested.map((child) =>
										child.name === "Static Content Management" ? (
											<Tooltip title={child.name}>
												<MenuItem
													key={child.path}
													onClick={() => {
														listItemClick(child);
													}}
												>
													{child.name}
												</MenuItem>
											</Tooltip>
										) : (
											<MenuItem
												key={child.path}
												onClick={() => {
													listItemClick(child);
												}}
											>
												{child.name}
											</MenuItem>
										)
									)}
								</SubMenu>
							) : (
								<MenuItem
									key={route.path}
									icon={route.sidebar.icon}
									onClick={() => {
										listItemClick(route);
									}}
								>
									{route.sidebar.name}
								</MenuItem>
							);
						})}
					</MenuStyled>
					{/* </Sider> */}
				</SidebarWrapper>
			)}
		</>
	);
}

export default withRouter(Sidebar);
