import React, { useState } from "react";
import { Input, Form, Button, Alert } from "antd";
import Api from "Helpers/ApiHandler";
// STYLES
import { LoginWrapper, LoginBox, LogoWrapper } from "./ForgotPassword.style";
import LogoImage from "Assets/Images/logo.png";
import { useParams } from "react-router-dom";

export default function ResetPassword(props) {
	let [loading, setLoading] = useState(false);
	const [isReseted, setIsReseted] = useState(false);
	let params = useParams();

	async function formSubmit(values) {
		try {
			setLoading(true);
			let config = {
				data: {
					password: values.password,
					verificationToken: params.token,
				},
				returnUnhandledError: true,
			};
			await new Api().post("/anqa/v1/user/client/cms/reset-password", config);
			setLoading(false);
			setIsReseted(true);
		} catch (error) {
			setLoading(false);
			console.log("error", error.response);
		}
	}

	return (
		<LoginWrapper className="flex f-v-center f-h-center">
			<LoginBox className="box">
				{isReseted ? (
					<Alert
						message="Your Password Reseted Successfully!"
						description="You can go to app and login with your new password."
						type="success"
						showIcon
					/>
				) : (
					<>
						<LogoWrapper>
							<img className="img" src={LogoImage} alt="Logo" />
						</LogoWrapper>
						<h1 style={{ textAlign: "center" }}>Reset Password</h1>
						<Form className="form-container" onFinish={formSubmit}>
							<Form.Item
								name="password"
								rules={[
									{
										required: true,
										message: "Please enter your password",
									},
									{
										pattern: new RegExp(
											"^(?=.*[a-z0-9])" +
												"(?=.*[A-Z])" +
												"(?=.*[!@#$%^&*+=._-])" +
												"(?=\\S+$).{8,}$"
										),
										message:
											"Password must contain minimum 8 characters &  atleast one special character & one uppercase character!",
									},
								]}
							>
								<Input.Password size="large" placeholder="New Password" />
							</Form.Item>
							<Form.Item
								name="confirmPassword"
								dependencies={["password"]}
								hasFeedback
								rules={[
									{
										required: true,
										message: "Please confirm your password",
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue("password") === value) {
												return Promise.resolve();
											}
											return Promise.reject(
												new Error(
													"The two passwords that you entered do not match!"
												)
											);
										},
									}),
								]}
							>
								<Input.Password size="large" placeholder="Confirm Password" />
							</Form.Item>
							<div className="button-container">
								<Button
									shape="round"
									className="submitButton btn"
									loading={loading}
									htmlType="submit"
								>
									Reset password
								</Button>
							</div>
						</Form>
					</>
				)}
			</LoginBox>
		</LoginWrapper>
	);
}
