
import { ACTION_TYPES } from './Actions';

let Data = localStorage.getItem('LiveSportGet') || "";
const newData = Data && JSON.parse(Data);
const initState = {
    isLiveSportGet: localStorage.getItem('isLiveSportGet') ? (localStorage.getItem('isLiveSportGet') === "true") : false,
    LiveSportGet: newData,
    sportDetail: {
        team1Name: newData.team1Name,
        team2Name: newData.team2Name,
        date: newData.time
    },
    golfSportDetail: {
        golfTournamentName: newData.golfTournamentName,
        startTime: newData.startTime,
        endTime: newData.endTime,
    }
}

const SportDetailReducer = (state = initState, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_SPORT_DETAIL:
            return {
                ...state,
                isLiveSportGet: true,
                LiveSportGet: action.sportData,
                sportDetail: {
                    team1Name: action.sportData.team1Name,
                    team2Name: action.sportData.team2Name,
                    team1Score: action.sportData.team1Score,
                    team2Score: action.sportData.team2Score,
                    time: action.sportData.time
                }
            };

        case ACTION_TYPES.SET_GOLF_SPORT_DETAIL:
            return {
                ...state,
                isLiveSportGet: true,
                LiveSportGet: action.sportData,
                golfSportDetail: {
                    golfTournamentName: action.sportData.golfTournamentName,
                    startTime: action.sportData.startTime,
                    endTime: action.sportData.endTime,
                }
            };

        default:
            return state;
    }

}

export default SportDetailReducer;