export const ACTION_TYPES = {
    TOAST_MESSAGE_ENABLE: "TOAST_MESSAGE_ENABLE",
    TOAST_MESSAGE_DISABLE: "TOAST_MESSAGE_DISABLE",
    SET_SELECTED_SPORT: "SET_SELECTED_SPORT",
    COLLAPSE_BAR: "COLLAPSE_BAR",
}

export const showToast = (message) => {
    return {
        type: ACTION_TYPES.TOAST_MESSAGE_ENABLE,
        message: message || ''
    }
}
export const hideToast = () => {
    return {
        type: ACTION_TYPES.TOAST_MESSAGE_DISABLE
    }
}

//-----
export const sidebar = () => {
	return {
		type: ACTION_TYPES.COLLAPSE_BAR,
	};
};

export const setSelectedSportId = (data) => {
    // let 
    return {
        type: ACTION_TYPES.SET_SELECTED_SPORT,
        ...data
    }
}
