
import { ACTION_TYPES } from './Actions';

let Data = localStorage.getItem('QuestionDetail') || "";
const newData = Data && JSON.parse(Data);
const initState = {
    QuestionDetail: newData,
}

const QuestionDetailReducer = (state = initState, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_QUESTION_DETAIL:
            return {
                ...state,
                QuestionDetail: action.questionData,
            };

        default:
            return state;
    }

}

export default QuestionDetailReducer;